<template>
  <!--
    This is simple comment
  -->
  <v-app>
    <AppBar/>
    <MyCarousel/>


    <v-footer dark padless>
      <v-card flat tile style="width: 100%" class="green lighten-1 white--text text-center">
        <v-card-text>
          <a v-for="icon in icons" :key="icon" :href="icon.url" class="mr-5">
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </a>
        </v-card-text>
        <v-card-text class="white--text pt-0">
          This footer description
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>MY COMPANY</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import MyCarousel from "@/components/MyCarousel.vue";

export default {
  // This is simple comment
  name: 'App',
  components: {MyCarousel, AppBar},
  data: () => ({
    icons: [
      {icon: 'mdi-facebook', url: "http://www.facebook.com/falcao.garc"},
      {icon: 'mdi-twitter', url: "http://www.twitter.com"},
      {icon: 'mdi-linkedin', url: "http://www.linkedin.com"},
      {icon: 'mdi-instagram', url: "http://www.instagram.com"},
    ],
  }),
  methods: {

  }
};
</script>